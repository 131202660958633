<template>
  <div class="Events">
    <Title label="Мероприятия" border />
    <!--    TODO: на старте не понадобится-->
    <!--    <div class="events__input">-->
    <!--      <Dropdown placeholder="Тип" size="3" />-->
    <!--      <Dropdown-->
    <!--        placeholder="Уровень мероприятий"-->
    <!--        :items="['1', '2', '3']"-->
    <!--        :keys="['textsdjksdkjfhdsjfjsdf1', 'text2', 'text3']"-->
    <!--        v-model="test"-->
    <!--      />-->
    <!--      <Input class="events__search" icon-type="Search" placeholder="Найти" />-->
    <!--    </div>-->
    <div class="events__grid events-grid">
      <!--Grid--item-->
      <CardEvent
        v-for="(item, index) in sortedProjectList"
        :key="index"
        :data="item"
      />
      <CardEventHardcode
        v-for="(item, index) in eventsItems"
        :key="index"
        :data="item"
      />
    </div>
  </div>
</template>

<script>
// import Input from "@/components/Blocks/Input";
// import Dropdown from "@/components/Blocks/Dropdown";
import CardEvent from "@/components/EventSection/CardEvent";
import Title from "@/components/Blocks/Title";
import { mapGetters } from "vuex";
import moment from "moment";
import CardEventHardcode from "@/components/EventSection/CardEventHardcode";
import eventsItems from "@/enums/eventsItems";
export default {
  name: "Events",
  components: {
    CardEventHardcode,
    Title,
    CardEvent,
    // Dropdown,
    // Input
  },
  data() {
    return {
      test: 1,
      eventsItems,
    };
  },
  computed: {
    sortedProjectList() {
      let buffer = JSON.parse(
        JSON.stringify(Object.values(this.projectsList))
      ).map((i) => {
        i.isClosed = this.currentStageEvent(
          i.settings?.stages?.data[i.settings?.stages?.data.length - 1]?.end_at
        );

        return i;
      });
      return buffer
        .sort((a, b) => {
          return b.version - a.version;
        })
        .sort((a, b) => {
          return Number(a.isClosed) - Number(b.isClosed);
        });
    },
    ...mapGetters(["projectsList"]),
  },
  methods: {
    currentStageEvent(value) {
      return moment().isAfter(value);
    },
  },
};
</script>

<style lang="scss">
.events {
  &__input {
    display: flex;
    gap: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--background--btn--profile--hover);
    flex-wrap: wrap;
    @include adaptive(phone) {
      padding-bottom: 5px;
      row-gap: 0px;
    }
    //& div:nth-child(2) {
    //}
    & div:last-child {
      flex: 0 1 250px;
      margin-left: auto;
    }
  }
  &__search {
    @include adaptive(tablet-small) {
      display: none;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 40px;
    margin-top: 40px;
    @include adaptive(desktop-small) {
      gap: 15px;
      margin-top: 20px;
    }
    @include adaptive(phone) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }
}
</style>
