<template>
  <router-link
    :to="{ name: 'EventPage', params: { id: data.id } }"
    class="events-grid__item"
  >
    <div class="events-grid__body">
      <div class="events-grid__img">
        <div v-html="data.image_poster_html"></div>
        <div class="status events-grid__status status--competition">
          {{ stageValueTotal }}
        </div>
      </div>
      <div class="events-grid__title">{{ data.title }}</div>
      <div class="events-grid__text">
        {{ data.description }}
      </div>
    </div>

    <div class="events-grid__footer">
      <div class="events-grid__date">
        {{ data.settings.stages.data[0]?.start_at_format }} -
        {{
          data.settings.stages.data[data.settings.stages.data.length - 1]
            ?.end_at_format
        }}
      </div>
      <div class="btn">Подать заявку</div>
    </div>
  </router-link>
</template>

<script>
import { allPeriodWorking } from "@/utils/helpers";
import moment from "moment";
export default {
  name: "CardEvent",
  props: ["data"],
  computed: {
    poster() {
      return `${process.env.VUE_APP_BACKEND_URL}${this.data.image_poster}`;
    },
    currentStage() {
      return this.data.settings.stages.data.find((item) => {
        if (
          moment().isBetween(
            moment(item.start_at),
            moment(item.end_at),
            "day",
            "[]"
          )
        ) {
          return item;
        }
      })?.title;
    },
    currentStageAbroad() {
      let buffer;
      let text;
      if (this.data?.settings?.stages?.data) {
        buffer = JSON.parse(JSON.stringify(this.data?.settings?.stages?.data));
        if (moment().isBefore(buffer[0]?.start_at)) {
          text = "Ожидание конкурса";
        }
        if (moment().isAfter(buffer[buffer.length - 1]?.end_at)) {
          text = "Конкурс завершен";
        }
      }

      return text;
    },
    stageValueTotal() {
      return this.currentStageAbroad
        ? this.currentStageAbroad
        : this.currentStage
        ? this.currentStage
        : "Ожидание этапа";
    },
  },
  methods: {
    allPeriodWorking,
  },
};
</script>

<style lang="scss">
.events-grid {
  &__item {
    padding: 20px;
    border: 1px solid var(--background--btn--profile--hover);
    border-radius: 5px;
    transition: all 0.22s ease-in-out;
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include adaptive(tablet-small) {
      padding: 15px 20px;
    }
    @media (min-width: 991px) {
      &:hover {
        transform: scale(1.05);
        border-color: var(--event--hover);
      }
    }
  }
  &__img {
    position: relative;
    min-height: 215px;
    border-radius: 5px;
    margin-bottom: 24px;
    & img {
      @include full-absolute();
      object-fit: cover;
      border-radius: 5px;
    }
    & .events-grid__status {
      position: absolute;
      right: 14px;
      bottom: -13px;
    }
    @include adaptive(phone) {
      & img {
        object-fit: contain;
      }
    }
  }
  &__title {
    font-size: 2.2rem;
    line-height: 2.7rem;
    font-weight: 500;
    margin-bottom: 4px;
    color: var(--text--color);
  }
  &__text {
    margin-bottom: 14px;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: var(--text--color);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
  }
  &__date {
    color: var(--input--border);
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 5px;
    .btn {
      white-space: nowrap;
    }
  }
}
</style>
